@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Merriweather:wght@300;400&display=swap');

body {
  font-family: 'Merriweather', serif;
  background-color: #f0f4f8;
  color: #333;
}

.header-image {
  width: 100%;
  height: 30%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.blog-content {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-title {
  font-family: 'Playfair Display', serif;
  font-size: 2.5em;
  color: #003366;
  margin-bottom: 15px;
}

.read-time {
  font-family: 'Merriweather', serif;
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
  display: block;
}

.audio-content {
  width: 100%;
  margin-bottom: 25px;
}

.introduction {
  font-family: 'Merriweather', serif;
  font-size: 1.2em;
  color: #555;
  margin-bottom: 25px;
}

.alert-info {
  background-color: #003366;
  color: #ffffff;
  font-family: 'Merriweather', serif;
  font-size: 1em;
  margin-top: 25px;
}

.sidebar {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
