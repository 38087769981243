.carouselItem img {
  max-height: 400px;
  object-fit: cover;
  width: 100%;
}

.smallImage {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}
.mainHeaderText {
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: 100px;
  color: white;
}

.subHeaderText {
  font-size: 1.8rem;
  margin-bottom: 70px;
  margin-top: 70px;
  line-height: 60px;
  color: #3c4c5d;
}
.headerText {
  font-size: 2rem;
  margin-bottom: 15px;
  margin-top: 80px;
  margin-left: 70%;
  line-height: 60px;
}

.backgroundContainer {
  background-image: url('../../../About/focus_stew.jpg'); /* Replace with your image path */
  background-size: cover; /* Ensure the image covers the area */
  background-position: center; /* Center the image */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  display: flex; /* Flex to center content */
  flex-direction: column; /* Align content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  color: white; /* Ensure text is readable on the background */
  text-align: center; /* Center the text */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  left: 0;
  overflow: hidden; /* Ensure no overflow of background */
  clip-path: polygon(
    0 85%,
    100% 100%,
    100% 0,
    0 0
  ); /* Crop bottom 15% of the image */
  margin-bottom: 20%;
}

.mainCenterText {
  font-size: 1.2rem;
  max-width: 80%; /* Limit text width to improve readability */
  font-family: sans-serif;
}

.centerText {
  text-align: center;
  margin: 0 auto;
  padding: 20px 0;
}

.hashtagSpan {
  /* Position span to the far right */
  font-size: .6rem;
  margin-bottom: 5px;
  margin-top: 5px;
  font-style: italic;
  font-weight:lighter;
  font-family:Georgia, 'Times New Roman', Times, serif;
}

.mb5 {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 10rem; /* Increase margin for medium screens and above */
  }
}
